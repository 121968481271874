import { createConnector } from 'react-instantsearch-core';

const CURRENT_PATHNAME = window.location.pathname;
const hitTransforms = {
  prodv3_ce_websearch: hit => ({
    isAdTile: true,
    adTileTitle: hit.tileTitle,
    adTileSubtext: hit.subtext,
    adTileMobileText: hit.mobileText,
    adTileButtonCtaText: hit.buttonCtaText,
    adTileImage: hit.image,
    adTileLink: hit.linkUrls[CURRENT_PATHNAME]
  })
};

export const ConnectInjectedHits = createConnector({
  displayName: 'InjectedHits',
  // eslint-disable-next-line id-length
  getProvidedProps(props, _, searchResults) {
    const { slots, hits, contextValue: { mainTargetedIndex }, displacement, enableAdTiles } = props;

    const results = searchResults.results || [];
    const isSingleIndex = Array.isArray(results.hits);
    const resultsByIndex = isSingleIndex
      ? { [mainTargetedIndex]: { ...results, hits } }
      : Object.entries(results).reduce((acc, [indexName, indexResults]) => {
        const isMainIndex = indexName === mainTargetedIndex;

        return {
          ...acc,
          [indexName]: isMainIndex ? { ...indexResults, hits } : indexResults
        };
      }, {});

    const mainIndexHits = resultsByIndex[mainTargetedIndex]?.hits || [];
    const isFirstPage = resultsByIndex[mainTargetedIndex]?.offset === 0;
    let adSpaceOnThisPage = false;
    let adSpaceOnFirstPage = false;

    if (!enableAdTiles) {
      return {
        injectedHits: mainIndexHits
      };
    }

    const injectedHits = mainIndexHits.map((hitFromMainIndex, position) => slots({ resultsByIndex })
      .reverse()
      .reduce((acc, { injectAt, getHits = () => [null], injectionType }) => {
        const hitsFromSlotIndex = getHits({ position, resultsByIndex, hitFromMainIndex });
        const shouldInjectIndex = injectAt({ position, resultsByIndex, hitFromMainIndex, hitsFromSlotIndex });
        if (shouldInjectIndex !== -1) {
          adSpaceOnFirstPage = true;
        }
        if (shouldInjectIndex === -1 || !isFirstPage) {
          return acc;
        }
        adSpaceOnThisPage = true;
        const transformedHitsFromSlotIndex = hitsFromSlotIndex.map(hitTransforms[injectionType])[shouldInjectIndex];
        return shouldInjectIndex !== -1 ? [...transformedHitsFromSlotIndex, ...acc] : acc;
      }, [hitFromMainIndex])
    ).flat();

    if (adSpaceOnThisPage) {
      injectedHits.splice(-displacement);
    } else if (!isFirstPage && adSpaceOnFirstPage) {
      injectedHits.splice(-displacement);
    } else if (!isFirstPage) {
      injectedHits.splice(0, displacement);
    }

    return {
      injectedHits
    };
  }
});
